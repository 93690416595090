<!-- 模版 -->
<template>
        <div class="T_box">
            <Head :Target='Target' :RightLeft='RightLeft' @show='pshow=true' :UpLayerData='UpLayerData' />
            <!-- 问题音频 -->
            <AudioPlayer ref="AudioPlayer" :theUrl="AudioPlayerUrl" @audioEndedOver="audioEndedOver" :config="AudioPlayerConfig" />
            <!-- <video :id="`Question`" class="video-js vjs-default-skin vjs-big-play-centered flex-grid">
            </video> -->
            <div class="T_content">
                <div class="Unit_d">
                    <div class="Unit_con">
                        <!-- <div class="ShuiYinRight">中国民用航空西北地区空中交通管理局 </div> -->
                        <!-- <div class="ShuiYinLeft">{{Username}}</div> -->
                        <div class="Second_title">
                            <div class="Audio_play">
                                <span class="t1" style="font-weight:bold;font-style:italic;">NO. {{questionIndex + 1}}/{{questionAll}}</span>
                                <div></div>
                                <div class="describe" @click="pshow = !pshow"  v-if="Pattern!='测试模式'">
                                    <img :src=" require( '@/assets/shuoming.png')" alt="">
                                    <span>Directions</span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="Question_box clearfix">
                            <div :class="['question_des',(mshow.text!='2'&&mshow.notes!='2')?'active':'']">
                                <div class="title">Read Words</div>
                                <div class="question_scroll">
                                    <table>
                                        <tr>
                                            <td class="soundmark">Letter</td>
                                            <td class="soundmark" v-if="this.isShowWord">Words</td>
                                            <td class="underlineLetter" style="width:250px">Pronunciation</td>
                                            <td class="xiaolaba"></td>
                                        </tr>
                                        <tr v-for="(todo,index) in this.questionDetail" :key="index" >
                                            <td class="word" @click="readClick(todo)"><p class="letter">{{todo.hintContent}}</p></td>
                                            <td class="soundmark" v-if="todo.soundmark!=null && todo.soundmark !=''">{{todo.soundmark}}</td>
                                            <td class="underlineLetter" style="width:250px">
                                                <span>{{getUnderlineWord(todo.underlineLetter)}}</span> {{getNolineWord(todo.underlineLetter)}}
                                                <!-- <p class="wordLine">{{getUnderlineWord(todo.underlineLetter)}}</p><p class="wordNoLine">{{getNolineWord(todo.underlineLetter)}}</p> -->
                                            </td>
                                            <!-- <td class="underlineLetter">{{todo.underlineLetter.substring(todo.underlineLetter.indexOf("_"),todo.underlineLetter.lastIndexOf("_"))}}{{todo.underlineLetter.substring(todo.underlineLetter.lastIndexOf("_"))}}</td> -->
                                            <td class="xiaolaba"><img @click="readClick(todo)" src="../../assets/icon_horn.png" /></td>
                                        </tr>
                                    </table>       
                                </div>          
                            </div>
                            
                            <div class="Notes" v-show="mshow.notes==2">
                                <div class="title"> Notes  <i class="el-icon-close right" @click="threeShowClose"></i> </div>
                                <div class="t_content">
                                    <textarea name="" v-model="NoteItem.content" @focus="NoteFocus()" @blur="NoteBlur()" maxlength="500" id="" cols="30" rows="10"></textarea>
                                    <img v-if="NoteItem.content.length<=0" src="../../assets/notesbc.png" class="bc" alt="">
                                    <button class="save" @click="SaveNotes"> Save </button>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
                <div class="T_option">
                    <div class="Unit_option">
                        <div class="btn_list">
                        <!-- back -->
                        <span class="btn" v-if="Pattern!='测试模式'" @click="On_back()"></span>
                        <!-- next -->
                        <span class="btn2" v-if="Pattern!='测试模式'" @click="On_next()"></span>                     
                            <div class="Record_btn" v-if="RecordeItem.show">
                                <img src="../../assets/Microphone.png" class="T_1" alt="" @click="Record_a()">
                                    <img src="../../assets/pause.png" class="T_2" alt="" @click='MediaPlay()' v-if="Pattern!='测试模式' && this.RecordeItem.MediaInPlay">
                                    <img src="../../assets/play_bai.png" class="T_2" alt="" @click='MediaPlay()' v-if="Pattern!='测试模式' && !RecordeItem.MediaInPlay&&RecordeItem.RecorderAudio==0">  
                                    <img src="../../assets/play_bai.png" class="T_2" alt="" @click='MediaPlay()' v-if="Pattern!='测试模式' && !RecordeItem.MediaInPlay&&RecordeItem.RecorderAudio!=0">   
                                <div class="Re_conm"  v-if='RecordBtn'>
                                    <span class="horn"></span>
                                    <div class="Re_box">
                                        <div id="Wave">
                                            <div v-for="i in 10" class="WaveItem" :key="i" :style="'height:'+ Wheight(i) +'px'">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="countdown"> {{RecordeItem.timeshow}} </div>
                                </div>
                            </div>
                            <div class="btn_cover"  v-if='RecordBtn'></div>
                        </div>
                        <Time :Pattern='Pattern'/>
                    </div>
                </div>
            </div>
            
            <!-- <div class="Machine">
                <img v-if="mshow.text==0" src="../../assets/TEXT3.png" alt="" style="cursor:not-allowed;">
                <img v-if="mshow.text==1" src="../../assets/1TEXT.png" alt="" @click="Clicktext">
                <img v-if="mshow.text==2" src="../../assets/2TEXT.png" alt=""  @click="mshow.text=1">
                <img v-if="mshow.notes==0" src="../../assets/NOTES3.png" alt=""  style="cursor:not-allowed;">
                <img v-if="mshow.notes==1" src="../../assets/1NOTES.png" alt=""  @click="mshow.notes=2;mshow.text=0;mshow.key=0;">
                <img v-if="mshow.notes==2" src="../../assets/2NOTES.png" alt=""  @click="mshow.notes=1">
                <img v-if="mshow.key==0" src="../../assets/KEY3.png" alt=""  style="cursor:not-allowed;">
                <img v-if="mshow.key==1" src="../../assets/1KEY.png" alt="" @click="mshow.key=2;mshow.notes=1;mshow.text=1;">
                <img v-if="mshow.key==2" src="../../assets/2KEY.png" alt="" @click="mshow.key=1">
            </div> -->
            <Search v-if="this.$store.state.common.searchActive" quesTempId='50A26ECF8CC84454945641071A21C504'></Search>
            <popup :show.sync="pshow" :describe='this.Target.description'></popup>
            <div class="Re_cover" v-if='RecordBtn'></div>
        </div>

</template>

<script>
    // import recording from '../../js/luyin.js'
    import popup from '../describe/Popup.vue'
    import Head from '../../components/head.vue'
    import Search from "./Search2"
    export default {
        data(){
            return {
                mshow:{
                    text:1,
                    notes:1,
                    key:1,
                    // 0 不可选 1 可选 2 已选
                },
                Panduan:true,
                Target:{
                    catalog:'',
                    description:'',
                    isEnd:null,
                    title:'',
                },
                RightLeft:false,
                pshow:false,
                questionData:{},

                questionIndex:0,
                questionAll:0,
                childQuestionListIndex:0,
                childQuestionListAll:0,
                questionDetail:[],

                // 播放器 参数  命名 不要重复   mixin 中使用了 参数 
                GetTime:false, // 获取 视频总时长
                audioTime:0, // 滑块 value
                player:null, // 播放器
                playertime:'', //总时长
                InPlay:false, // 判断是否 播放中
                // 播放器 参数

                NoteItem:{
                    id:'',
                    content:'',
                },
                // 绑题 规则 
                BindRole:{},
                
                // 录音 
                RecordeItem:{
                    show:true,
                    time:null,
                    timeshow:'',
                    Count:null,
                    begin:false,
                    audio:null,
                    getAudio:null,
                    Media:null, // 录音播放 多媒体
                    MediaInPlay:false,  // 录音播放状态
                },

                wave:null,
                waveIndex:0,
                //是否作答
                hasRecording:false,

                isShowWord:false,
                wordLine:"",
                wordNoLine:"",
                UpLayerData:{},
                AudioPlayerUrl:"",//题干音频地址
                AudioPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[],
                },
            }
        },
        components:{popup,Head,Search},
        created() {
            if(this.menulist.length>0){
                this.Panduan = true;
                this.getinfo();
            }else{
                this.Panduan = false;
            }

        },
        mounted(){
            this.RecordeItem.Media = document.createElement('audio');
            let Question_box = document.querySelector('.Question_box').clientHeight;
            let list = document.querySelectorAll('.Question_box>div');
            for (let i = 0; i < list.length; i++) {
                const element = list[i];
                element.style.height = (Question_box-17) + 'px';
            }
        },
        computed:{
            datacomplate:function(){
                return this.$store.state.common.datacomplate;
            },
            dataLayer:function(){
                return this.$store.state.common.dataLayer;
            },
            RecordBtn:function(){
                return this.$store.state.common.RecordBtn;
            },
        },
        watch:{
            datacomplate:function(v){
                if(!this.Panduan){
                    this.getinfo();
                }
            },
            dataLayer:function(){
                this.getinfo();
            },
        },
        methods:{
            Wheight(i){
                let t = i - 1;
                let c = Math.abs(Math.abs((Math.floor(t/5)*5 - t%5)) * 40 - 300) - 30;
                let d = 300*300 - c*c;
                d = Math.sqrt(d)*2;
                return d;
            },
            
            async getinfo(){
                this.$store.commit('setitem',{'name':'threeList','item':['KNOWLEDGESEARCHING']});
                let data = this.GetTargetItem(); 
                if(!!data.last){
                    this.Target = data.data[data.last];
                }else{
                    this.Target = data.data;
                }
                this.UpLayerData = data.updata; // 上 层数据
                this.Target.title = data.title;
                this.BindRole = this.Target.bindRole;
                if(this.BindRole.isRecordNote == 1) this.mshow.notes = 1;
                else this.mshow.notes = 0;

                if(this.BindRole.isReadAnswer == 1) this.mshow.key = 1;
                else this.mshow.key = 0;

                if(this.BindRole.isReadOriginal  == 1) this.mshow.text = 1;
                else this.mshow.text = 0;

                if(this.Target.isEnd == 1 && data.data.length>1){
                    this.RightLeft = true;
                }else{
                    this.RightLeft = false;
                }
                    console.log(this.Target.bindRole.ruleTypeName,'44444');
                if(this.Target.isEnd == 1){
                    sessionStorage.setItem('Pattern',this.Target.bindRole.ruleTypeName);
                    this.$store.commit('setitem',{'name':'Pattern','item':this.Target.bindRole.ruleTypeName})
                }
                let qIndex = sessionStorage.getItem('questionIndex') || 0;
                this.questionIndex = sessionStorage.getItem('SonquestionIndex') || 0; // 问题子题下标
                this.questionAll = this.Target.questionList.length; // 问题 总数

                let _oldId_ = this.Target.questionList[qIndex].oldId;
                let questiondata = await this.GetQuestion(_oldId_,this.Target.sectionMain.id);
                this.questionData = questiondata.data;
                this.childQuestionListAll = this.questionData.childQuestionList.length;

                this.questionDetail = JSON.parse(this.questionData.childQuestionList[this.childQuestionListIndex].title);
                for(let i = 0;i < this.questionDetail.length;i++){
                    let info = this.questionDetail[i];
                    if(info.soundmark != null && info.soundmark !=""){
                        this.isShowWord = true;
                    }
                }
                this.AutoPlayWords(this.questionDetail[0].audioUrl)

                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.questionData.childQuestionList[0].id);

                if(notes_.data.length>0){
                    this.NoteItem = notes_.data[0];
                }else{
                    this.NoteItem.content = '';
                    this.NoteItem.id = '';
                }
                // let show = recording.check();
                this.RecordeItem.show = false;
                this.$store.commit('setitem',{'name':'RecordBtn','item':false})
                // this.FlashPlay();
            },
            // 测试模式 自动播放单词
            AutoPlayWords(url){
                if(this.BindRole.isAutoPaly === 1){
                    this.AudioPlayerUrl = url
                    this.AudioPlayerConfig.autoplay = true
                }
            },
            // 单词播放完成
            audioEndedOver(){
                // this.On_next()
                console.log('000');
            },
            // 保存 笔记
            async SaveNotes(){
                let question = this.questionData.childQuestionList[this.childQuestionListIndex];
                let item = this.NoteItem;
                item.content = item.content.replace(/(^\s*)|(\s*$)/g,'');
                if(!!!item.content){
                    this.$message.warning('请填写笔记!');
                    return false;
                }

                let layer = sessionStorage.getItem('targetceng').split(',');
                layer = layer.map(el=>{
                    el = el.split('.');
                    return el;
                })
                let data = {
                    courseId:this.menulist[layer[0][1]].courceId,
                    nodeId:this.Target.id,
                    title:'',
                    content:item.content,
                    questionId:question.id,
                    levelName:this.Target.catalog,
                }

                if(!!item.id){
                    let edit_ = await this.AjaxApi.updateCourseNote(item);

                    if(edit_.code == '200'){
                        this.$message.success('修改笔记成功!');
                    }
                }else{
                    let add_ = await this.AjaxApi.addCourseAdvice(data);

                    if(add_.code == '200'){
                        this.$message.success('添加笔记成功!');
                        this.NoteItem.id = add_.data.id;
                    }
                }

            },

            // 录音  控制
            Record_a(it){
                this.$store.commit('setAdd','MenuSwitch');
                if(this.InPlay){
                    this.InPlay = false;
                    this.player.pause();
                }
                if(this.RecordeItem.MediaInPlay){
                    this.RecordeItem.Media.pause();
                    this.RecordeItem.MediaInPlay = false;
                }
                // 检测是否有麦克风
                let checkInfo = JSON.parse( sessionStorage.checkInfo ) 
                if( !!!checkInfo.audioInput){
                    this.$message.warning("无音频输入设备!");
                    return
                }

                this.waveIndex = 0;

                if(!!this.BindRole.recordDownTime&&this.BindRole.recordDownTime>0){
                    this.RecordeItem.time = this.BindRole.recordDownTime;
                }else{
                    this.RecordeItem.time = 35;
                }
                this.RecordeItem.timeshow = window.ComputedTime(this.RecordeItem.time);
                
                if(this.RecordBtn){
                    clearInterval(this.wave);
                    clearInterval(this.RecordeItem.Count);
                    let list = document.querySelectorAll('#Wave div');
                    for (let index = 0; index < list.length; index++) {
                        let el = list[index];
                        el.className = '';
                    }
                    recording.stopRecording();
                    this.RecordeItem.getAudio = setInterval(()=>{
                        if(!!recording.MediaItem){
                            this.RecordeItem.audio = recording.MediaItem;
                            this.RecordeItem.Media.src = recording.audioUrl;
                            this.UpdateAudio();
                            clearInterval(this.RecordeItem.getAudio);
                        }
                    },100)
                    this.$store.commit('setitem',{'name':'RecordBtn','item':false})
                }else{
                    this.wave = setInterval(()=>{
                        let list = document.querySelectorAll('#Wave div');
                        if(list[this.waveIndex ].className.indexOf('active')>-1){
                            list[this.waveIndex ].className = '';
                        }else{
                            list[this.waveIndex ].className = 'active';
                        }
                        if(this.waveIndex<9){
                            this.waveIndex++;
                        }else{
                            this.waveIndex = 0;
                        }
                    },35)
                    this.RecordeItem.Count = setInterval(()=>{
                        if(this.RecordeItem.time>0){
                            this.RecordeItem.time --;
                            this.RecordeItem.timeshow = window.ComputedTime(this.RecordeItem.time);
                        }else{
                            recording.stopRecording();
                            this.RecordeItem.getAudio = setInterval(()=>{
                                if(!!recording.MediaItem){
                                    this.RecordeItem.audio = recording.MediaItem;
                                    this.RecordeItem.Media.src = recording.audioUrl;
                                    this.UpdateAudio();
                                    this.$store.commit('setitem',{'name':'RecordBtn','item':false})
                                    clearInterval(this.RecordeItem.getAudio);
                                }
                            },100)
                            clearInterval(this.wave);
                            clearInterval(this.RecordeItem.Count);
                        }
                    },1000)
                    recording.startRecording();
                    this.$store.commit('setitem',{'name':'RecordBtn','item':true})
                }
                    this.hasRecording ==true;
            },
            async UpdateAudio(){
                let url = "/uploadFile";
                let formFile = new FormData();
                formFile.append("action", "UploadVMKImagePath");  
				formFile.append('filename', 'mp3Name');
                formFile.append("file" ,this.RecordeItem.audio); //加入文件对象
                let url_;
                try{
                    url_ = await this.$upload.post(url,formFile);
                }catch(e){
                    this.$message.error('上传文件失败!');
                }
            },

            //  滑块 tooltip
            formatTooltip(val){
                if(!!this.player){
                    let c = parseFloat((val / 100) * this.player.duration()).toFixed(2);
                    if(c!=NaN&&c!='NaN') c += 's'
                    else c = 's'
                    return c;
                }else{
                    return '';
                }
            },
            MediaPlay(){
                if(!!!this.RecordeItem.Media.src){
                    this.$message.warning('请先录制音频!');
                    return false;
                }
                if(this.InPlay){
                    this.InPlay = false;
                    this.player.pause();
                }
                if(this.RecordeItem.MediaInPlay) this.RecordeItem.Media.pause();
                else this.RecordeItem.Media.play();
                this.RecordeItem.MediaInPlay = !this.RecordeItem.MediaInPlay;
                
            },
            // 重新 播放
            ResetTime(){
                this.RecordeItem.Media.pause();
                this.RecordeItem.MediaInPlay = false;
                this.audioTime = 0;
                this.player.volume(1);
                this.player.currentTime(0.5);
                this.player.play();
                this.InPlay = true;
            },
            // 滑块  播放时段改变
            TimeChange(){
                this.RecordeItem.Media.pause();
                this.RecordeItem.MediaInPlay = false;
                let time = (this.audioTime / 100) *　this.player.duration();
                this.player.volume(1);
                this.player.currentTime(time);
                this.InPlay = true;
            }, // 上一题
            async prevQuestion(){
                this.questionIndex--;
                this.QuestionChange();
            },
            // 下一题
            async nextQuestion(){
                this.questionIndex++;
                this.QuestionChange();
            },
            // 数据 改变
            async QuestionChange(){
                // 数据还原
                this.childQuestionListIndex = 0;
                this.GetTime = false; // 获取 视频总时长
                this.audioTime = 0; // 滑块 value
                this.playertime = ''; //总时长
                this.InPlay = false; // 判断是否 播放中
                this.ProgressBar = {
                    show:false,
                    width:100,
                    time:null,
                    duration:0,
                    timeing:0,
                }

                let questiondata = await this.GetQuestion(this.Target.questionList[this.questionIndex].oldId,this.Target.sectionMain.id);
                this.questionData = questiondata.data;  // 当前问题 数据
                this.childQuestionListAll = this.questionData.childQuestionList.length;// 当前问题 子题总数
                this.questionDetail = JSON.parse(this.questionData.childQuestionList[this.childQuestionListIndex].title);

                this.AutoPlayWords(this.questionDetail[0].audioUrl)
                
                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.questionData.childQuestionList[0].id);

                if(!!notes_.data&&notes_.data.length>0){
                    this.NoteItem = notes_.data[0];
                }else{
                    this.NoteItem.content = '';
                    this.NoteItem.id = '';
                }
                // this.FlashPlay();
                this.InfoComplate = true;
            },
            // 上一子题
            prevChildQuestion(){
                this.childQuestionListIndex--;
                this.ChildDataChange();
            },
            // 下一子题
            nextChildQuestion(){
                this.childQuestionListIndex++;
                this.ChildDataChange();
            },
            async ChildDataChange(){
                console.log('下一子题');
                this.Question = {
                    audio:null,
                    ready:false,
                    status:false,
                };
                this.NoteItem = {//笔记 
                    id:'',
                    content:'',
                };

                this.childQuestionData = this.questionData.childQuestionList[this.childQuestionListIndex];
                this.childQuestionData.check = '';
                
                this.questionDetail = JSON.parse(this.questionData.childQuestionList[this.childQuestionListIndex].title);
                // this.AutoPlayWords(this.questionDetail[0].audioUrl)
                console.log(this.questionDetail);
                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.questionData.childQuestionList[this.childQuestionListIndex].id);
                
                if(!!notes_.data&&notes_.data.length>0){
                    this.NoteItem = notes_.data[0];
                }else{
                    this.NoteItem.content = '';
                    this.NoteItem.id = '';
                }
                if(this.Pattern == '测试模式'){
                    this.QuestionParameter.autostart = true;
                    // this.$message.info('将播放问题音频!');
                }
                // this.QuestionReady(); // 问题音频 设置

            },
            // 问题音频 描述设置
            QuestionReady(){
                this.Question.audio = jwplayer('Question');
                this.Question.audio.setup(this.QuestionParameter);
                this.player.onBufferFull(()=>{
                    this.Question.ready = true;
                })
                this.Question.audio.onComplete(()=>{
                    if(this.Pattern == '测试模式'){
                        this.QuesetionComplete();
                    }
                    this.Question.status = false;
                })

            },
            On_back(){
                this.T_back(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
            },
            On_next(){
                if(this.$store.state.common.MaxPattern=='闯关模式'){
                    if(!!this.hasRecording){
                        this.T_next(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
                    }else{
                        this.$message.warning('请先完成本题!');
                    }
                }else{
                    this.T_next(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
                }
            },
            readClick(todo){
                // this.AudioPlayerUrl = todo.audioUrl.substring(12,todo.audioUrl.length)
                this.AudioPlayerUrl = todo.audioUrl
                setTimeout(()=>{
                    this.$refs.AudioPlayer && this.$refs.AudioPlayer.RefreshChangeCurrentTime()
                },100)
                // todo.audioUrlName;            
                // let src = this.FlashUrl(todo.audioUrl);
                // this.FlashPlay(src); // 题干音频 设置
                // console.log("点击Item播放音频"+src)    
                // if(!!this.player){
                //     this.player.reset(); //重置 video
                //     this.player.src([
                //         {
                //             type:"rtmp/mp4",
                //             src:src,
                //         }
                //     ]);
                //     this.player.load();
                //     this.player.currentTime(0.5);
                //     this.player.play();
                //     return false;
                // }
                
                // let item = {
                //     width:'500',
                //     height:'500',
                //     controls:true,
                //     loop:false,
                //     preload:'auto',
                //     autoplay:true,
                //     sources:[
                //         {
                //             type:"rtmp/mp4",
                //             src:src,
                //         }
                //     ],
                // };
                // this.player = videoJs('Question');
                // this.player.on('ready',()=>{
                //     this.player.play();
                // })
                // this.player.on('canplaythrough',()=>{
                //     this.player.play(); 
                // })
            },
            getUnderlineWord(word){
                let tempWord = "";
                let first = 0;
                let last = 0;
                first = word.indexOf("_")+1;
                last = word.lastIndexOf("_");

                tempWord = word.substring(first,last);
                return "    "+tempWord;
            },
            getNolineWord(word){
                let tempWord = "";
                let last = 0;
                last = word.lastIndexOf("_")+1;

                tempWord = word.substring(last);
                
                return tempWord;
            }
        },
        destroyed() {// 删除 播放器
            if(!!this.player){
                this.player.remove();
            }
            clearInterval(this.wave);
            clearInterval(this.RecordeItem.Count);
        },
    }
</script>


<style lang="less" scoped>
    .Record_btn{
        visibility: hidden;
    }
    li{
        text-align: center;
        padding: 10px;
    }
    li:hover{
        background: #FFF;
        // border:1px solid grey;
        // background: #cd6600;
        box-shadow: 2px 2px 2px 2px #E5E8EA;
    }
    ul{
        overflow-y:auto;
        height: 100%;
    }
    table{
        overflow-y:auto;
        height: auto;
        table-layout: fixed;
        margin-top: 20px;
        width: 100%;
        border: 1px;
        .word{
            text-align: center;
            color: #0089e9; 
            text-decoration: underline;
            
        }
        .soundmark{
            text-align: center; 
        }
        .underlineLetter{
            margin: 10px 0;
            text-align: center;
            span{
                text-decoration:underline;
            }
        }
        .underlineLetter .wordLine{
            padding-bottom:2px; 
            border-bottom:1px solid #000;
            align-self: center;
        }
        .underlineLetter .wordNoLine{
            align-self: center;
            padding-bottom:3px; 
            margin-left: 5px;
            cursor:auto;
        }
        .xiaolaba{
            text-align: center;
        }
        img{
            visibility: hidden;
            cursor: pointer;
        }
        tr{
            height: 40px;
            &:hover .xiaolaba img{
                text-align: right;
                visibility: visible;
            }
        }
        tr:hover{
            background: #FFF;
            // border:1px solid grey;
            // background: #cd6600;
            box-shadow: 2px 2px 2px 2px #E5E8EA;
            
        }
        td{
            font-weight:bold;
            .letter{
                cursor: pointer;
            }
        }
    }
    .question_scroll{
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }
    .Question_list {
        margin-top: 10px;
    }
    .Question_list span{
        color: #45b64b;       
    }
    .contentInputNodes{
        width: calc(100% - 10px);
        height: calc(100% - 30px);
        max-width: calc(100% - 10px); 
        margin-top: 20px;
        display: block;
        resize:none;
        border-style:none;
        padding: 5px;
    }
</style>

